
import { communications_constants } from '../_constants/communications';
import { communications_service } from '../_services/communications';
import { alertActions } from './alert';
import Swal from 'sweetalert2'


export const communications_actions = {
    get_all_communications
};

function get_all_communications(idCommunication) {

    return dispatch => {
        dispatch(request());

        communications_service.get_all_communications(idCommunication)
            .then(
                res => {
                    if (res.hasOwnProperty('error')) {
                        dispatch(failure(res.error.message.toString()));
                    } else {
                        dispatch(success(res.communications));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: communications_constants.COMMUNICATIONS_REQUEST, payload: true } }
    function success(param) { return { type: communications_constants.COMMUNICATIONS_SUCCESS, payload: param } }
    function failure(error) { return { type: communications_constants.COMMUNICATIONS_FAILURE, payload: error } }
}


import React from 'react';
import { useHistory } from 'react-router';


const CursoIniciado = ({ curso }) => {
    const history = useHistory();

    const route = history.location.pathname;

    const redireccionar = (id) => history.push(`curso/${id}`);

    return ( 
        <div className="flex items-center justify-between mb-3">
            <div className="w-10/12">
                <p className="text-sm">{curso.fullname}</p>
                <div className={route === '/inicio' || route === '/eventos' || route === '/cursos' ? 'h-2 relative w-full rounded-full overflow-hidden mb-2' : 'h-4 relative w-10/12 rounded-full overflow-hidden mb-2'}>
                    <div className="w-full h-full bg-gray-700 absolute"></div>
                    <div className="h-full bg-green_bar absolute" style={{ width: `${curso.progress}%` }}></div>
                </div>
                <p className="text-xs text-gray-700 cursor-pointer" onClick={ () => redireccionar(curso.id) } > Continuar curso </p>
            </div>
            <div className="w-2/12">
            <p className={route === '/inicio' || route === '/eventos' || route === '/cursos' ? "text-gray-800 text-12" : 'text-gray-700'}>{ Number.parseFloat(curso.progress).toFixed(2) }%</p>
            </div>
        </div>
    );
}
 
export default CursoIniciado;
import React, { useEffect, useState } from "react";
import Aside from "../../Components/Aside/Aside";
import Calendario from "../../Components/Calendario/Calendario";
import Ranking from "../../Components/Ranking/Ranking";
import ListSlider from "../../Components/Slider/ListSlider";
import { cursos_actions } from "../../store/_actions/cursos";
import { useDispatch, useSelector } from "react-redux";
import CarouselList from "../../Components/Slider/CarouselList";
import Index from "../../assets/css/index.scss";

const Inicio = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const get_cursos_inscritos = () =>
      dispatch(cursos_actions.getCursosInscritos());
    get_cursos_inscritos();
  }, []);

  const cursos_disponibles = useSelector((state) => state.cursos.cursos);
  const cursos = cursos_disponibles.filter((item) => item.progress == 0 || item.progress == null);


  return (
    <>
      <div className="flex flex-col flex-wrap sm:flex-row">
        <Aside cursos={cursos} />

        <div className="flex flex-col flex-wrap justify-center w-full lg:w-2/3 lg:flex-nowrap">
          <div className="w-full">
            {cursos.length > 0 ? <CarouselList title="Mis cursos disponibles" cursos={cursos} /> : null}
          </div>
          <div className="flex flex-row justify-center">
            <div className="m-5 w-full md:w-2/3 lg:w-1/2"><Calendario /></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Inicio;

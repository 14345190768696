import { reconocimiento, reconocimientos_all } from '../_constants/reconocimientos';
import { reconocimientos_service } from '../_services/reconocimientos';

import { alertActions } from './alert';

export const reconocimientos_actions = {
    getreconocimientos,
    get_all_reconocimientos
};

function getreconocimientos() {
    return dispatch => {
        dispatch( request() );

        reconocimientos_service.getreconocimientos()
            .then(
                reconocimientos => {
                    if(reconocimientos.badges.length > 0 ){
                        dispatch(success(reconocimientos.badges));
                    }else{
                        dispatch(failure(reconocimientos.error.toString()));
                        dispatch(alertActions.error(reconocimientos.error.toString()));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: reconocimiento.RECONOCIMIENTOS_REQUEST , payload: true } }
    function success(badges) { return { type: reconocimiento.RECONOCIMIENTOS_SUCCESS , payload: badges } }
    function failure(error) { return { type: reconocimiento.RECONOCIMIENTOS_FAILURE , payload: error } }
}

function get_all_reconocimientos() {
    return dispatch => {
        dispatch( request() );

        reconocimientos_service.get_all_reconocimientos()
            .then(
                reconocimientos => {
                    if(reconocimientos.badges.length > 0 ){
                        dispatch(success(reconocimientos.badges));
                    }else{
                        dispatch(failure(reconocimientos.error.toString()));
                        dispatch(alertActions.error(reconocimientos.error.toString()));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: reconocimientos_all.RECONOCIMIENTOS_ALL_REQUEST , payload: true } }
    function success(badges) { return { type: reconocimientos_all.RECONOCIMIENTOS_ALL_SUCCESS , payload: badges } }
    function failure(error) { return { type: reconocimientos_all.RECONOCIMIENTOS_ALL_FAILURE , payload: error } }
}
import React from 'react';


const Reconocimiento = ({ url_img, text, lock, name }) => {
    return (
        <div className={`flex w-10/12 mb-4 items-center md:w-1/2 lg:w-1/2 ${ lock ? 'opacity-50	' :  '' }`}>
            <div className="mr-1">
                <img style={{ width: '70px', height:'70px' }} src={url_img} alt=""/>
            </div>
            <div className="flex justify-center flex-col">
                <p className="font-black uppercase">{name}</p>
                <p>{ text }</p>
            </div>
        </div>
    );
}
 
export default Reconocimiento;
import { certificado, certificado_custom } from '../_constants/certificados';
import { certificados_service } from '../_services/certificados';

import { alertActions } from './alert';

export const certificados_actions = {
    getCertificados,
    getCertificadosCustom
};

function getCertificados() {
    return dispatch => {
        dispatch( request() );

        certificados_service.getCertificados()
            .then(
                certificados => {
                    if(certificados.issues.length > 0 ){
                        dispatch(success(certificados.issues));
                    }else{
                        dispatch(failure(certificados.error.toString()));
                        dispatch(alertActions.error(certificados.error.toString()));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: certificado.CERTIFICADOS_REQUEST , payload: true } }
    function success(issues) { return { type: certificado.CERTIFICADOS_SUCCESS , payload: issues } }
    function failure(error) { return { type: certificado.CERTIFICADOS_FAILURE , payload: error } }
}

function getCertificadosCustom() {
    return dispatch => {
        dispatch( request() );

        certificados_service.getCertificadosCustom()
            .then(
                certificados => {
                    if(certificados.issues.length > 0 ){
                        dispatch(success(certificados.issues));
                    }else{
                        dispatch(failure(certificados.error.toString()));
                        dispatch(alertActions.error(certificados.error.toString()));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: certificado_custom.CUSTOM_CERTIFICADOS_REQUEST , payload: true } }
    function success(issues) { return { type: certificado_custom.CUSTOM_CERTIFICADOS_SUCCESS , payload: issues } }
    function failure(error) { return { type: certificado_custom.CUSTOM_CERTIFICADOS_FAILURE , payload: error } }
}
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { cursos_actions } from "../../store/_actions/cursos";

import DetallesUsuario from '../../Components/MiPerfil/DetallesUsuario';
import Privacidad from '../../Components/MiPerfil/Privacidad';
import CursosInscritos from '../../Components/MiPerfil/CursosInscritos';

import ActividadesRecientes from '../../Components/MiPerfil/ActividadesRecientes';
import Reportes from '../../Components/MiPerfil/Reportes';
import AppMobile from '../../Components/MiPerfil/AppMobile';


const MiPerfil = () => {

    // const user = {
    //     email: 'example@hm.com',
    //     pais: 'Mexico',
    //     perfil: 'NA',
    // }
 
    const dispatch = useDispatch();

    useEffect(() => {
        const get_cursos_inscritos = () => dispatch(cursos_actions.getCursosInscritos());
        get_cursos_inscritos();
    },[]);

    const cursos = useSelector(state => state.cursos.cursos);
   
    const reportes = [
        'Sesiones del navegador',
        'Vista general de calificaciones.',
    ]
    return ( 
        <>
            {/* <Nav/> */}

            <div className="flex bg-white">
                <div className="p-8 w-full">

                    <div className="flex flex-wrap items-start w-full mt-5 mb-5">
                        <div className="w-full lg:w-1/3">
                            <DetallesUsuario />
                            <Privacidad/>
                            <CursosInscritos cursos={cursos}/>
                        </div>
                        <div className="w-full lg:w-1/3">
                            {/* <ActividadesRecientes/> */}
                            <Reportes reportes={reportes}/>
                            {/* <AppMobile/> */}
                        </div>
                    </div> 
                </div>
            </div>

            {/* <Footer/> */}
        </>
    );
}
 
export default MiPerfil;
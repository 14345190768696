import { config } from './config';
import api_iac from '../../config/instance_axios';

export const cursos_services = {
    getCursosInscritos,
    getCurso
};

let user = JSON.parse(localStorage.getItem('user'));

async function getCursosInscritos() {
    const token = user.token;
    // const token_course = '01d00c50b7b8bf5cfb2a9df2f26a1598'
    const id = user.userid;

    const data = [];

    const consulta = await api_iac.post(`webservice/rest/server.php?
        wstoken=${token}&
        wsfunction=core_enrol_get_users_courses&
        userid=${id}&
        moodlewsrestformat=json`
    );

    const consulta_grade = await api_iac.post(`webservice/rest/server.php?wstoken=${config.token}&wsfunction=gradereport_overview_get_course_grades&userid=${id}&moodlewsrestformat=json`);
    
    await consulta.data.forEach((item, i) => {
        if(consulta_grade.data.grades[i].courseid != undefined){
            if(item.id == consulta_grade.data.grades[i].courseid ){
                item.grade = consulta_grade.data.grades[i].grade
            }
        }   
        data.push(item)
    });

    return data;
}

async function getCurso(id) {
    const token = user.token;

    const consulta = await api_iac.post(`webservice/rest/server.php?
        wstoken=${token}&
        wsfunction=core_course_get_contents&
        courseid=${id}&
        moodlewsrestformat=json`
    );
    return consulta.data;
}
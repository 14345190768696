import React, { useEffect, useRef, useState } from 'react';

import buscador from '../../assets/img/buscador.svg'

const BuscadorMobile = () => {
    const [open, setOpen] = useState(false)

    return ( 
        <div className="content_search">
            <div onClick={ () => setOpen(!open) } className="mr-1" style={{ cursor: 'pointer' }}>
                <div> <img style={{ width: '20px' }} src={buscador} alt="buscador"/> </div>
            </div>

            { open ?
                <div>
                    <div style={{ top: '85px' }} onClick={ () => setOpen(false) } className="fixed top-0 left-0 bg-blue-700 bg-opacity-50 z-40 min-h-screen w-full"></div>
                    <div className="search flex justify-center bg-black bg-opacity-60 w-full">
                        <input className="p-1 rounded bg-white bg-opacity-50" type="text" name="" id="" placeholder="Escribe tu busquedad aqui"/>
                    </div>
                </div>
                
            : null }
        </div>
     );
}
 
export default BuscadorMobile;
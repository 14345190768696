import React, { useEffect, useState } from 'react';

import logo from '../assets/img/logo.png'
import dafi from '../assets/img/dafi_white.png'
import regresar from '../assets/img/regresar.png'


import { useHistory } from "react-router-dom";

const Footer = () => {
    let history = useHistory();

    function handleClick() {
        history.goBack();
    }

    console.log(history.location.pathname.split('/')[1])

    return (
        <> 
            { history.location.pathname == '/inicio' || history.location.pathname == '/' || history.location.pathname.split('/')[1] == 'secuencia' ?  
               <></>
            : 
            <div className="hidden md:flex justify-end my-4 ">
                <div onClick={handleClick}>
                    <img src={regresar} alt=""/>
                </div> 
            </div>
            }
        </>
    );
}  
 
export default Footer;
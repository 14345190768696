import React from 'react';
import Aside from '../../Components/Aside/Aside';
import Calendario from '../../Components/Calendario/Calendario';
import Ranking from '../../Components/Ranking/Ranking';




const Eventos = () => {
    return ( 
        <>
        <div className="flex flex-col flex-wrap sm:flex-row">
            <Aside/>
            <div className="flex flex-wrap justify-center w-full lg:w-2/3 lg:flex-nowrap">
                <div className="m-5 w-full md:w-2/3 lg:w-1/2">
                    <Calendario/>
                </div>
                {/* <div className="m-5 w-full md:w-2/3 lg:w-1/2">
                    <Ranking/>
                </div>                 */}
            </div>
        </div>
        </>
    );
}
 
export default Eventos;
import axios from "axios";

const api_iac = axios.create({
    //baseURL: 'https://local.hmartepost.com/moodle38/',
    //baseURL: 'https://universidadiac.com.mx/desarrollo/',
    //baseURL: 'http://corelms.test/',
     baseURL: 'https://www.regal.sescomm.com/corelms/',
    timeout: 90000,
});


export default api_iac;
import React, { useEffect, useState } from 'react';
import susses_dove from '../../assets/img/susses_dove.svg';
import play from '../../assets/img/play.svg';
import lock_gray from '../../assets/img/lock_gray.png';

import { Link, useHistory } from "react-router-dom";
import api_iac from '../../config/instance_axios';
import { useSelector } from 'react-redux';

const ListaItemCurso = ({ modulo_prop, itemId, idCurso }) => {
    const history = useHistory();
    const user = useSelector(state => state.authentication.user );
    const [modulo, setModulo] = useState(modulo_prop)


    const redireccionar = (cm) => {

        if (modulo.modname != 'customcert' && modulo.modname != 'certificate' && modulo.modname != 'resource' && modulo.modname != 'url' && modulo.modname != 'dctres' && modulo.modname != 'quiz' && modulo.forumtype != 'general' && modulo.forumtype != 'news') {

            history.push(`/tema/${modulo.instance}/${modulo.id}/${modulo.name}`)
        } else if (modulo.modname == 'customcert') {
            return download_certificado_custom()
        } else if (modulo.modname == 'certificate') {
            return download_certificado()
        } else if (modulo.modname == 'resource') {
            let name = modulo.name;
            localStorage.setItem('recurso', name);
            history.push(`/recurso/${idCurso}/${modulo.instance}`)
        } else if (modulo.modname == 'url') {
            let name = modulo.name;
            localStorage.setItem('url', name);
            history.push(`/url/${idCurso}/${modulo.instance}`)
        } else if (modulo.modname == 'dctres') {
            let name = modulo.modname;
            localStorage.setItem('dctres', name);
            history.push(`/dctres/${idCurso}/${modulo.instance}`)
        } else if (modulo.modname == 'quiz') {
            let name = modulo.modname;
            localStorage.setItem('quiz', name);
            history.push(`/temaquiz/${modulo.instance}`)
        } else if (modulo.forumtype == 'general') {
            let name = modulo.forumtype;
            localStorage.setItem('forum', name);
            history.push(`/forum/${modulo.instance}`)
        }else if (modulo.forumtype == 'news') {
            let name = modulo.forumtype;
            localStorage.setItem('communication', name);
            history.push(`/communication/${modulo.instance}`)
        }
    }

    const download_certificado = async () => {
        const request = await api_iac.post(`webservice/rest/server.php?
            wstoken=${user.token}&
            wsfunction=mod_certificate_get_user_certificate_download&
            moodlewsrestformat=json&
            certificateid=${modulo.id}&
            userid=${user.userid}
        `)
        const link = document.createElement("a");
        link.href = `data:application/pdf;base64,${request.data.issues}`;
        link.setAttribute("download", "certificado.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
        const modulo_temp =  modulo;
        modulo_temp.completiondata.state = 1;
        return setModulo({ ...modulo, modulo_temp }) 
        // setLink(`${request.data.issues[0].fileurl}?token=${user.token}`)
    }

    const download_certificado_custom = async () => {
        const request = await api_iac.post(`webservice/rest/server.php?
            wstoken=${user.token}&
            wsfunction=mod_customcert_get_user_certificate_custom_view&
            moodlewsrestformat=json&
            certificateid=${modulo.id}&
            userid=${user.userid}
        `)
        const link = document.createElement("a");
        link.href = `data:application/pdf;base64,${request.data.issues}`;
        link.setAttribute("download", "certificado.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
        const modulo_temp =  modulo;
        modulo_temp.completiondata.state = 1;
        return setModulo({ ...modulo, modulo_temp }) 
    }

    let icono = '';
    try {
        icono =  require(`../../assets/img/iconos/actividades/${modulo.modname}.png`).default;
    } catch (error) {
        icono =  require(`../../assets/img/iconos/actividades/scorm.png`).default;
    }

    return (
        <>
            {modulo.modname != 'customcert' || modulo.modname != 'certificate' || modulo.modname == 'resource' || modulo.modname == 'url' || modulo.modname == 'quiz' || modulo.forumtype != 'general' || modulo.forumtype != 'news' ?
                    <div onClick={ () => modulo.availabilityinfo == undefined ? redireccionar(itemId ) : null } className={  modulo.availabilityinfo == undefined ? 'cursor-pointer group mb-2 list-none' : 'cursor-default mb-2 list-none' }>
            <ol  className="px-4 list-none">
                <li className="flex items-center justify-between">
                    <div className="flex items-center">
                        <div className="flex justify-center items-center border rounded-full mr-1" style={{ height: '30px', width: '30px' }} >
                            <img style={{ transform: 'rotate(90deg)' }} src={play} alt="" />
                        </div>

                        <img className="mr-1" style={{ height: '25px', width: '27px' }} src={ icono } alt="" />

                        <p>{ modulo.name } { modulo.availabilityinfo == undefined ? '' : <span className="text-gray-600" dangerouslySetInnerHTML={{ __html: modulo.availabilityinfo  }}/> } </p>
                    </div>

                    { modulo.availabilityinfo == undefined ? 
                        modulo?.completiondata?.state == 1 ? 
                        <div className="border border-gray-700 flex justify-center items-center" style={{ width: '30px', height: '30px' }}> <img src={susses_dove} alt="" /> </div>
                        :   <div className="border-gray-700 border" style={{ width: '30px', height: '30px' }}></div>
                    :  <img src={lock_gray} alt="bloqueado"/>  }
                </li>
            </ol>
        </div>
                :
                <div onClick={ modulo.availabilityinfo == undefined ? () => redireccionar(itemId ) : null } className='cursor-pointer group mb-2 list-none' className={  modulo.availabilityinfo == undefined ? 'cursor-pointer group mb-2 list-none' : 'cursor-default mb-2 list-none' }>
            <div>
                <ol  className="px-4 list-none">
                    <li className="flex items-center justify-between">
                        <div className="flex items-center">
                            <div className="flex justify-center items-center border rounded-full mr-1" style={{ height: '30px', width: '30px' }} >
                                <img style={{ transform: 'rotate(90deg)' }} src={play} alt="" />
                            </div>

                            <img className="mr-1" style={{ height: '25px', width: '27px' }} src={ icono } alt="" />

                            <p>{ modulo.name } { modulo.availabilityinfo == undefined ? '' : <span className="text-gray-600" dangerouslySetInnerHTML={{ __html: modulo.availabilityinfo  }}/> } </p>
                        </div>

                        { modulo.availabilityinfo == undefined ? 
                            modulo?.completiondata?.state == 1 ? 
                            <div className="border border-gray-700 flex justify-center items-center" style={{ width: '30px', height: '30px' }}> <img src={susses_dove} alt="" /> </div>
                            :   <div className="border-gray-700 border" style={{ width: '30px', height: '30px' }}></div>
                        :  <img src={lock_gray} alt="bloqueado"/>  }
                    </li>
                </ol>
            </div>
        </div>
            }
        </>
    );
}

export default ListaItemCurso;
import api_iac from '../../config/instance_axios';

export const certificados_service = {
    getCertificados,
    getCertificadosCustom
};

let user = JSON.parse(localStorage.getItem('user'));

async function getCertificados() {
    const token = user.token;
    const id = user.userid;

    const consulta = await api_iac.post(`webservice/rest/server.php?
        wstoken=${token}&
        wsfunction=mod_certificate_get_user_certificates&
        userid=${id}&
        moodlewsrestformat=json`
    );

    return consulta.data;
}


async function getCertificadosCustom() {
    const token = user.token;
    const id = user.userid;

    const consulta = await api_iac.post(`webservice/rest/server.php?
        wstoken=${token}&
        wsfunction=mod_customcert_get_user_certificates_custom&
        userid=${id}&
        moodlewsrestformat=json`
    );

    return consulta.data;
}
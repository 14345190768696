import React from 'react';

import logo from '../../assets/img/logo.png'
import Buscador from './Buscador';
import HeaderPerfil from './HeaderPerfil';
import ItemsMenu from './ItemsMenu';


const Nav = () => {
    return ( 
        <>
            <nav className="bg-black text-white hidden max-h-screen lg:flex">
                <ItemsMenu/>
            </nav>
        </>
        
    );
}
 
export default Nav;
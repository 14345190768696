import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api_iac from '../../config/instance_axios';
import Swal from 'sweetalert2';
import { questions_actions } from '../../store/_actions/questions';


const Quiz = (props) => {
    const { quizid } = props.match.params;
    const user = useSelector(state => state.authentication.user);

    const [dataAnswersQuiz, setDataAnswersQuiz] = useState([]);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [attemptid, setAttemptid] = useState();


    const detalles = async (idQuiz) => {
        const request_detalles = await api_iac.post(`webservice/rest/server.php?
            wstoken=${user.token}&
            wsfunction=targett_get_exam_attempt&
            moodlewsrestformat=json&
            id=${idQuiz}`
        );

        const res = await request_detalles.data;
        //console.log(res);

        setAttemptid(res.attemptid)
        setDataAnswersQuiz(res.questions);
    }

    //console.log(dataAnswersQuiz);
    useEffect(() => {
        detalles(quizid);
    }, [])

    const radioBtnHandler = async (ev, index) => {

        const sequencekey = ev.target.getAttribute('sequencekey');
        const sequencevalue = ev.target.getAttribute('sequencevalue');

        let answSelect = selectedAnswers;
        let indexSum = index + index;
        let countIndexSum = indexSum + 1;

        answSelect[indexSum] = {
            idAnswer: ev.target.value,
            nameAnswer: ev.target.name
        }
        answSelect[countIndexSum] = {
            idAnswer: sequencevalue,
            nameAnswer: sequencekey
        }

        setSelectedAnswers(answSelect);

    };

    const handleSubmit = async (selectedAnswers, attemptid, quizid) => {

        if (dataAnswersQuiz.length * 2 === selectedAnswers.length) {

            const alert_send = await Swal.fire({
                title: "¿Seguro que desea enviar las respuestas?",
                text: "Se finalizara el intento",
                icon: "warning",
                dangerMode: true,
                showCancelButton: true,
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar'
            })

            if (alert_send.isConfirmed == true) {
                await questions_actions.send_questions(selectedAnswers, attemptid, quizid);
            }
        }
        else {
            await Swal.fire({
                title: "¡Debe seleccionar todas las opciones!",
                icon: "warning",
            })
        }
    }

    return (
        <>
            <div className="container mx-auto px-4 lg:w-4/6 h-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-10 my-10 answersBoxes">
                {dataAnswersQuiz.length > 0 ?
                    dataAnswersQuiz.map((item, i) => (
                        <>

                            <div className="boxShadow col-span-5">
                                <div className="answerInfo">
                                    <div className="numQuestion">
                                        <p>Pregunta {item.id}</p>
                                    </div>
                                </div>
                                <div className="answerForm">
                                    <p className="tittleQuestion mb-4">{item.questiontext}</p>
                                    {item.qtype === 'multichoice' ?
                                        <>
                                            <p className="selectAnswer mb-2 font-bold">Seleccione una: </p>
                                            {
                                                item.answers.map((answer, index) => (
                                                    <div className="flex items-center">
                                                        <label htmlFor="">{String.fromCharCode(97 + index)}.- </label>
                                                        <input
                                                            type="radio"
                                                            id={item.answerkey}
                                                            name={item.answerkey}
                                                            value={answer.id}
                                                            sequencekey={item.sequencecheckkey}
                                                            sequencevalue={item.sequencecheckvalue}
                                                            onChange={(ev) => {
                                                                if (ev.target.value !== "") {
                                                                    radioBtnHandler(ev, i);
                                                                }
                                                            }}
                                                        /* onClick={(ev) => radioBtnHandler(ev, index)} */
                                                        />
                                                        <span> {answer.answer}</span>
                                                    </div>
                                                ))
                                            }
                                        </> :
                                        <>
                                            <p className="selectAnswer mb-2 font-bold">Elija una: </p>
                                            <div className="flex items-center">
                                                <input
                                                    type="radio"
                                                    id={item.answerkey}
                                                    name={item.answerkey}
                                                    sequencekey={item.sequencecheckkey}
                                                    sequencevalue={item.sequencecheckvalue}
                                                    value={item.answers = 1}
                                                    onChange={(ev) => {
                                                        if (ev.target.value !== "") {
                                                            radioBtnHandler(ev, i);
                                                        }
                                                    }}
                                                />
                                                <span> Verdadero</span>
                                            </div>
                                            <div className="flex items-center">
                                                <input
                                                    type="radio"
                                                    id={item.answerkey}
                                                    name={item.answerkey}
                                                    sequencekey={item.sequencecheckkey}
                                                    sequencevalue={item.sequencecheckvalue}
                                                    value={item.answers = 0}
                                                    onChange={(ev) => {
                                                        if (ev.target.value !== "") {
                                                            radioBtnHandler(ev, i);
                                                        }
                                                    }}
                                                />
                                                <span> Falso</span>
                                            </div>
                                        </>
                                    }
                                </div>

                            </div>
                        </>
                    ))
                    : 'Cargando...'
                }

            </div>
            <div className="container mx-auto px-4 pb-8 flex justify-end">
                <button type="button" className="btnFinishExam" onClick={() => handleSubmit(selectedAnswers, attemptid, quizid)}>Terminar intento...</button>
            </div>
        </>
    );
}


export default Quiz;
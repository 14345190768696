import React, { useEffect, useRef, useState } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import desplegar_menu from '../../assets/img/desplegar_menu.svg'
import { userAuthActions } from '../../store/_actions/user_auth';


const MenuOpciones = () => {
    const node = useRef();
    const [menu, setMenu] = useState(false)
    const dispatch = useDispatch();


    const logoutUser = () => {
        dispatch(userAuthActions.logout());
    }

    const user = useSelector(state => state.authentication.user );

    return ( 
        <>
            <div className="menu-opciones-perfil">
                <div className="flex items-center" onClick={ () => setMenu(!menu)  } >
                    <div className="rounded-full mx-2 bg-cover bg-center" style={{ width: '45px', height: '45px', backgroundImage: `url(${user.profileimage})` }}></div>
                    <div className="hidden lg:block mx-2" onClick={ () => setMenu(!menu) } > <img src={desplegar_menu} alt=""/> </div>
                </div>

                { menu ? 
                    <div>
                        <div style={{ top: '85px' }} onClick={ () => setMenu(false) } className="fixed top-0 left-0 bg-blue-700 bg-opacity-50 z-40 min-h-screen w-full"></div>
                        <div ref={node} className="menu-opciones-perfil-items z-50 bg-white w-24">
                            <ul>
                                <li className="p-1 hover:bg-blue-400 text-white"> <Link className="text-blue-500 text-xs" to="/mi-perfil">Mi perfil</Link> </li>
                                <li onClick={logoutUser} className="p-1 hover:bg-blue-400 text-white"> <Link className="text-blue-500 text-xs">Cerrar sesión</Link> </li>
                            </ul>
                        </div>
                    </div>
                : null }
            </div>
            
        </>
    );
}
 
export default MenuOpciones;
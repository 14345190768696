import React from 'react';

// Trofeo.png = calificaciones

const Tag = ({imagen,type, number}) => {

    const img = require(`../assets/img/${imagen}`)
    return ( 
        <>
         <div className="w-full sm:w-1/2 lg:w-1/3">
            <div className="flex flex-wrap bg-black rounded-b-3xl px-1 pt-2 pb-2 w-2/3 items-center justify-center m-auto">
                <div style={{ maxWidth: '90px', width: '100%' }}>
                    <img className="w-full" src={img.default} alt=""/>
                </div>
                <div className="text-center">
                    {  imagen == 'recompensa.png' ?  <p className="text-white text-sm">{ type }</p> :  <p className="text-white text-xs">{ type }</p> }
                   
                    <p className="text-white text-40 font-extrabold">{ number }</p>
                </div>
                </div>
            </div>
        </>
    );
}
 
export default Tag;
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Calificacion from "./Calificacion.scss";

const DetailsScore = ({ curso, isCurrentScore, onClose, isScore }) => {

  const activeClass = isCurrentScore ? "v-visible effectRight" : "effectLeft";

  const courseContent = useSelector((state) => state.cursos.curso);

  let totalBlocks = courseContent?.length;
  let totalActivites = 0;
  courseContent?.forEach(content => {
    totalActivites = totalActivites + content?.modules.length;
  });

  return (
    <div
    className={`max-w-2xl border-4 border-white bg-gray-card py-6 px-2 rounded-lg tracking-wide shadow-card containerScore absolute z-1000-less ${activeClass}`}
    id={`detailscore${curso.id}`}
    >
      <div className="text-end relative">
        <span className="absolute bg-blue-900 btn-close" onClick={onClose}>
          x
        </span>
      </div>
      <div className="flex">
        <div className="flex flex-col items-center part-content-card">
          <h2 className={`text-title`}>BLOQUES</h2>
          <span className="text-xs">{totalBlocks}</span>
        </div>
        <div className="flex flex-col items-center part-content-card">
          <h2 className="text-title">ACTIVIDADES</h2>
          <span className="text-xs">{totalActivites}</span>
        </div>

        <div className="flex flex-col items-center">
          <h2 className="text-title">CALIFICACIÓN</h2>
          <span className="text-xs">{isScore()}</span>
        </div>
      </div>
    </div>
  );
};

export default DetailsScore;

import { forums_constants } from '../_constants/forums';


const initialState = {
    forums: [],
    loading_forums: null,
    error_forums: null,

    loading_forums_create: null,
    forums_create: null,
    error_forums_create: null,

    loading_forums_edit: null,
    forums_edit: null,
    error_forums_edit: null,

    loading_forums_delete: false,
    forums_delete: false,
    error_forums_delete: false,
};

export function forums_reducers(state = initialState, action) {
    switch (action.type) {
        //------------GET FORUMS--------------//
        case forums_constants.FORUMS_REQUEST:
            return {
                ...state,
                loading_forums: action.payload,
            };
        case forums_constants.FORUMS_SUCCESS:
            return {
                ...state,
                loading_forums: false,
                forums: action.payload,
                error_forums: null
            };
        case forums_constants.FORUMS_FAILURE:
            return {
                ...state,
                loading_forums: false,
                error_forums: action.payload
            };

        //-------------CREATE FORUMS-----------------
        case forums_constants.FORUMS_CREATE_REQUEST:
            return {
                ...state,
                loading_forums_create: action.payload,
            };
        case forums_constants.FORUMS_CREATE_SUCCESS:
            return {
                ...state,
                loading_forums_create: false,
                forums_create: action.payload,
                error_forums_create: null
            };
        case forums_constants.FORUMS_CREATE_FAILURE:
            return {
                ...state,
                loading_forums_create: false,
                error_forums_create: action.payload
            };

        // ------------- EDIT FORUMS ----------
        case forums_constants.FORUMS_EDIT_REQUEST:
            return {
                ...state,
                loading_forums_edit: action.payload,
            };
        case forums_constants.FORUMS_EDIT_SUCCESS:
            return {
                ...state,
                loading_forums_edit: false,
                forums_edit: action.payload,
                error_forums_edit: null
            };
        case forums_constants.FORUMS_EDIT_FAILURE:
            return {
                ...state,
                forums_edit: 0,
                loading_forums_edit: false,
                error_forums_edit: action.payload
            };

        //------------DELETE FORUMS--------------//
        case forums_constants.FORUMS_DELETE_REQUEST:
            return {
                ...state,
                loading_forums_delete: action.payload,
                forums_delete: null,
                error_forums_delete: null
            };
        case forums_constants.FORUMS_DELETE_SUCCESS:
            return {
                ...state,
                loading_forums_delete: false,
                forums_delete: action.payload,
                error_forums_delete: false
            };
        case forums_constants.FORUMS_DELETE_FAILURE:
            return {
                ...state,
                loading_forums_delete: false,
                forums_delete: 0,
                error_forums_delete: action.payload
            };
        default:
            return state
    }
}
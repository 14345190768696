import React from 'react';
import Carousel, { CustomLeftArrow, CustomRightArrow } from 'react-multi-carousel';
import CardSlider from './CardSlider';
import moment from 'moment';



const CarouselList = ({ title, cursos }) => {

  const convertUnixtoDate = (unixTime) => {
    moment.locale('es')
    let formatTime = moment.unix(unixTime).format('DD/MM/YYYY')
    return formatTime;
  }

  return (
    <div className="my-5 mx-1 w-95/1">
      <h2 className="text-gray-200 font-bold text-20 md:ml-6 p-1 border border-gray-200 inline uppercase">{title}</h2>

      <Carousel
        additionalTransfrom={0}
        arrows={true}
        autoPlaySpeed={3000}
        centerMode={false}
        className=""
        containerClass="container"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={false}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={true}
        renderDotsOutside={false}
        // customLeftArrow={<CustomLeftArrow />}
        // customRightArrow={<CustomRightArrow />}
        responsive={{
          desktopXL: {
            breakpoint: {
              max: 3000,
              min: 1300
            },
            items: 4,
            partialVisibilityGutter: 40
          },
          desktopLG: {
            breakpoint: {
              max: 1300,
              min: 1024
            },
            items: 3,
            partialVisibilityGutter: 40
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0
            },
            items: 1,
            partialVisibilityGutter: 30
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464
            },
            items: 3,
            partialVisibilityGutter: 30
          }
        }}
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {cursos == undefined ? (
          <p className="uppercase text-8xl text-black">Cargando</p>
        ) : (
          cursos.map((card, i) => {

            let available = moment(new Date()).unix() <= card.startdate;
            const start = convertUnixtoDate(card.startdate);

            card.time = (i + 1) * 1;
            return (
              <div className="" key={i}>
                <CardSlider card={card} text={available === false ? 'Iniciar' : 'Proximamente'} dateStart={start} disponible={available} />
              </div>
            );
          })
        )}

      </Carousel>
    </div>
  )
}

export default CarouselList

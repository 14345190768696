import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api_iac from '../../config/instance_axios';
import moment from 'moment';
import 'moment/locale/es'
import Swal from 'sweetalert2';

import ModalAddTopic from '../../Components/Forum/addTopic';
import ModalEditTopic from '../../Components/Forum/editTopic';
import ModalAddResponseTopic from '../../Components/Forum/addResponseTopic';
import ModalAddResponsePost from '../../Components/Forum/addResponsePost';


import { forums_actions } from '../../store/_actions/forums';



const Forum = (props) => {
    const dispatch = useDispatch();

    const { forumid } = props.match.params;

    useEffect(() => {
        const getForums = () => dispatch(forums_actions.get_all_forums(forumid));
        getForums();
    }, []);

    const user = JSON.parse(localStorage.getItem('user'));
    const dataforums = useSelector(state => state.forums.forums);

    const handleDeleteDiscussion = async idDiscussion => {
        const alert_delet = await Swal.fire({
            title: "¿Seguro que desea eliminar el tema?",
            text: "No hay vuelta atras",
            icon: "warning",
            dangerMode: true,
            showCancelButton: true,
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar'
        })

        if (alert_delet.isConfirmed == true) {
            await forums_actions.discussion_delete(idDiscussion);
            dispatch(forums_actions.get_all_forums(forumid));
        }
    }

    const handleDeleteResponse = async idComment => {
        console.log(idComment);
        const alert_delet = await Swal.fire({
            title: "¿Seguro que desea eliminar el comentario?",
            text: "No hay vuelta atras",
            icon: "warning",
            dangerMode: true,
            showCancelButton: true,
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar'
        })

        if (alert_delet.isConfirmed == true) {
            await forums_actions.remove_comment_forum(idComment);
            dispatch(forums_actions.get_all_forums(forumid));
        }
    }

    const convertUnixtoDate = (unixTime) => {
        moment.locale('es')
        let formatTime = moment.unix(unixTime).format('dddd D MMMM YYYY, h:mm A')
        return formatTime;
    }


    return (
        <div className="container mx-auto px-4 pt-8 pb-32 h-screen overflow-y-scroll">
            <div className='addNewCard mb-20'>
                <h3 className='text-4xl font-black text-white mr-5'>Foro</h3>
                <ModalAddTopic idForo={forumid}></ModalAddTopic>
                <hr style={{ color: '#a9a9a9', backgroundColor: '#a9a9a9', height: 1, borderColor: '#a9a9a9', }} />

            </div>
            {dataforums.map((forum, index) => (
                <>
                    <div className="cardDiscussion mt-16 bg-white">
                        <div className='autorCard'>
                            <div className="rounded-full bg-cover" style={{ width: '40px', height: '40px', backgroundColor: '#e6e6e6', backgroundImage: `url(${forum.userpictureurl})` }}> </div>
                            <div className='autorContent ml-3'>
                                <span className='text-2xl font-black text-blue-500'>{forum.subject}</span>
                                <p>De: {forum.userfullname} - {convertUnixtoDate(forum.created)}</p>
                            </div>
                        </div>
                        <div className='messageForum my-5'>
                            <p>{forum.message}</p>
                        </div>
                        <div className='flex justify-end items-center mb-2 text-blue-500'>
                            <ModalAddResponseTopic forumId={forumid} discussionId={forum.id} sizeReply={forum.posts.length}></ModalAddResponseTopic>

                            {user.userid === forum.userid ?
                                <>
                                    <ModalEditTopic forumId={forumid} postId={forum.firstpost}></ModalEditTopic>
                                    <button type='button' className='pl-2 verticalLine' onClick={e => handleDeleteDiscussion(forum.id)}>Eliminar discusión</button>
                                </>
                                : ''}
                        </div>
                    </div>


                    {forum.posts.map((post, i) => (
                        <div className="cardDiscussion mt-5 ml-10 bg-white">
                            <div className='autorCard'>
                                <div className="rounded-full bg-cover" style={{ width: '40px', height: '40px', backgroundColor: '#e6e6e6', backgroundImage: `url(${post.userpictureurl})` }}> </div>
                                <div className='autorContent ml-3'>
                                    <span className='text-2xl font-black text-blue-500'>{post.subject}</span>
                                    <p>De: {post.firstname} {post.lastname} - {convertUnixtoDate(post.created)}</p>
                                </div>
                            </div>
                            <div className='messageForum my-5'>
                                <p>{post.message}</p>
                            </div>
                            <div className='flex justify-end items-center mb-2 text-blue-500'>
                                <ModalAddResponsePost forumId={forumid} postId={post.id} isPost={true}></ModalAddResponsePost>

                                {user.userid === post.userid ?
                                    <>
                                        <ModalEditTopic forumId={forumid} postId={post.id}></ModalEditTopic>
                                        <button type='button' className='pl-2 verticalLine' onClick={e => handleDeleteResponse(post.id)}>Eliminar discusión</button>
                                    </>
                                    : ''}
                            </div>
                        </div>

                    ))
                    }
                </>
            ))

            }
        </div>
    );
}



export default Forum;
export const certificado = {
    CERTIFICADOS_REQUEST: 'CERTIFICADOS_REQUEST',
    CERTIFICADOS_SUCCESS: 'CERTIFICADOS_SUCCESS',
    CERTIFICADOS_FAILURE: 'CERTIFICADOS_FAILURE',
}

export const certificado_custom = {
    CUSTOM_CERTIFICADOS_REQUEST: 'CUSTOM_CERTIFICADOS_REQUEST',
    CUSTOM_CERTIFICADOS_SUCCESS: 'CUSTOM_CERTIFICADOS_SUCCESS',
    CUSTOM_CERTIFICADOS_FAILURE: 'CUSTOM_CERTIFICADOS_FAILURE',
}
import React from 'react'

const Welcome = () => {
    return (
           <div className="hidden md:block">
                    <p className="bg-white py-3 px-6 text-blue-main text-26"> <span className="text-black font-bold">¡Bienvenido a</span> tu entrenamiento en línea!</p>
            </div> 
    )
}

export default Welcome

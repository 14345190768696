
import api_iac from '../../config/instance_axios';

export const communications_service = {
    get_all_communications
};

let user = JSON.parse(localStorage.getItem('user'));


async function get_all_communications(idCommunication) {
    const request_communications = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=targett_read_channel&
    &moodlewsrestformat=json&
    channelid=${idCommunication}`);

    if (Array.isArray(request_communications.data.announcements)) {
        const communications = request_communications.data.announcements;
        return { communications };
    }

    const error = request_communications.data;
    return { error };
}
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router'

const Buscador = () => {
    const [ busquedad, guardarBusquedad ] = useState('');
    const [ busquedadRes, guardarBusquedadRes ] = useState([]);
    const [ busquedadShow, guardarBusquedadShow ] = useState(false);

    // const inputRef = useRef();
    const obtenerCursos = useSelector(state => state.cursos.cursos);
    const history = useHistory();

    const redireccionar = (id) => {
        guardarBusquedadShow(false)
         history.push(`/curso/${id}`)
         window.location.reload();
    };

    const handleChange = e => {
        if(e != '' && e != undefined){
            const resultado = obtenerCursos.filter( item => {
                if(item){
                    return item.fullname.toLowerCase().search(e.toLowerCase()) !== -1;
                }
            });
            guardarBusquedadRes(resultado);
        }else{
            guardarBusquedadRes([])
        }
    }
    const handlerBlur = (type)=>{
        console.log(type);
        guardarBusquedadShow(false)
    }

    return ( 
        <>
            <div className="buscador hidden lg:block">
                <input onFocus={ () => guardarBusquedadShow(true) } onBlur={ () => handlerBlur("input") } onChange={(e)=>handleChange(e.target.value)} name="busquedad" className="bg-white p-1 font-xs" type="text" placeholder="Escribe tu búsqueda"/>    
                <i className="fas fa-search"></i>
            </div>
            {  busquedadRes.length > 0 && busquedadShow == true ?
                <div className="absolute bg-white top-0 w-full" style={{ transform: 'translateY(59px)', maxWidth: '198px' }}>
                { busquedadRes.map(item => (
                    <div onClick={ () => redireccionar(item.id) } className="p-1 text-black cursor-pointer hover:bg-gray-400"> <p className="text-sm">{ item.fullname }</p> </div>
                )) }
                </div>
            : null }
        </>
    );
}
 
export default Buscador;
import { questions_constants } from '../_constants/questions';


const initialState = {
    loading_questions_create: null,
    questions_create: null,
    error_questions_create: null,
};

export function questions_reducers(state = initialState, action) {
    switch (action.type) {
        //------------Send questions--------------//
        case questions_constants.QUESTIONS_CREATE_REQUEST:
            return {
                ...state,
                loading_questions_create: action.payload,
            };
        case questions_constants.QUESTIONS_CREATE_SUCCESS:
            return {
                ...state,
                loading_questions_create: false,
                questions_create: action.payload,
                error_questions_create: null
            };
        case questions_constants.QUESTIONS_CREATE_FAILURE:
            return {
                ...state,
                loading_questions_create: false,
                error_questions_create: action.payload
            };
        default:
            return state
    }
}
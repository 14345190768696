import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools";
import thunk from "redux-thunk";
import allReducers from "./_reducers";

import { auth } from '../middleware/auth';  

const store = createStore(
    allReducers,
    compose(applyMiddleware(
        thunk,
        auth
    ),
        typeof window === 'object' &&
            typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined' ?
                window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    ),
//   composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
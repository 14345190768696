import { questions_constants } from '../_constants/questions';
import { questions_service } from '../_services/questions';
import { alertActions } from './alert';
import Swal from 'sweetalert2';
import { history } from '../_helpers/history';


export const questions_actions = {
    send_questions
};

async function send_questions(answers, attempt, quizid) {

    await questions_service.send_questions(answers, attempt)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    failure(res.error.message.toString());
                    alertActions.error(res.error.toString());
                } else {
                    success(res);
                    history.push(`#/temaquiz/${quizid}`)
                    window.location.reload();
                }

            },
            error => {
                failure(error.toString());
                alertActions.error(error.toString());
            }
        ).catch(e => failure(e.toString()))


    function request() { return { type: questions_constants.QUESTIONS_REQUEST, payload: true } }
    function success(param) { return { type: questions_constants.QUESTIONS_SUCCESS, payload: param } }
    function failure(error) { return { type: questions_constants.QUESTIONS_FAILURE, payload: error } }
}
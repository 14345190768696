export const cursos = {
    CURSOS_REQUEST: 'CURSOS_REQUEST',
    CURSOS_SUCCESS: 'CURSOS_SUCCESS',
    CURSOS_FAILURE: 'CURSOS_FAILURE',
}


export const curso = {
    CURSO_REQUEST: 'CURSO_REQUEST',
    CURSO_SUCCESS: 'CURSO_SUCCESS',
    CURSO_FAILURE: 'CURSO_FAILURE',
}
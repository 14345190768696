import React from 'react';
import Cursos from './Components/Cursos';
import Reconocimientos from './Components/Reconocimientos';



const Aside = ({ cursos }) => {
    return ( 
        <aside className="flex flex-col w-full lg:w-30 bg-gray-500 p-4">
            <Cursos cursos={cursos}/>
        </aside>
    );
}
 
export default Aside;
import React, { useEffect } from 'react';
import Reconocimiento from '../../Components/Reconocimientos/Reconocimiento';
import Tag from '../../Components/Tag';
import { reconocimientos_actions } from '../../store/_actions/reconocimientos';
import { useDispatch, useSelector } from 'react-redux';

const Recompensas = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        const getReconocimientos = () => dispatch(reconocimientos_actions.getreconocimientos());
        const getReconocimientosAll = () => dispatch(reconocimientos_actions.get_all_reconocimientos());
        getReconocimientos();
        getReconocimientosAll();
    },[])

    const reconocimientos_user = useSelector(state => state.reconocimientos.reconocimientos);
    const reconocimientos_all = useSelector(state => state.reconocimientos.reconocimientos_all);

    const reconocimientos_loading = useSelector(state => state.reconocimientos.reconocimientos_loading);
    const reconocimientos_all_loading = useSelector(state => state.reconocimientos.reconocimientos_all_loading);
    let reconocimientos = [];

    if(reconocimientos_loading == false && reconocimientos_all_loading == false ){
        reconocimientos = reconocimientos_all.filter((item, i) => {
            if( reconocimientos_user[i] != undefined){
                item.success = false
                if(item.id == reconocimientos_user[i].id){
                    item.success = true
                }
            }
            return item;
        })
    }

    const token = useSelector(state => state.authentication.user.token);
    return ( 
        <div className="bg-white">
            {/* <Nav/> */}
            { reconocimientos_loading == false && reconocimientos_all_loading == false ? 
                <Tag imagen="reconocimientos.png" type="RECONOCIMIENTOS" number={reconocimientos_user.length}/>
            : null }

            <div>

                <div className="flex flex-col p-8">
                    <div>
                        <div className="mb-4">
                            <h3 className="text-2xl uppercase text-blue-500 font-black mb-3 text-center sm:text-left">Mis Reconocimientos</h3>
                        </div> 
                    </div>
                
                    <div className="flex flex-wrap justify-start w-full items-center mb-5">
                    { reconocimientos_loading == false && reconocimientos_all_loading == false ? 
                        reconocimientos.length > 0 ?
                            reconocimientos.map(item => (
                            <Reconocimiento key={item.id} url_img={`${item.badgeurl}?token=${token}`} lock={item.success} name={item.name} text={item.description}/>
                            ))
                        : 'Sin reconocimientos'
                    : null }
                    </div>
                </div>

            </div>

        </div>
    );
}
 
export default Recompensas;
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Aside from '../../Components/Aside/Aside';
import CarouselList from '../../Components/Slider/CarouselList';
import ListSlider from '../../Components/Slider/ListSlider';
import { cursos_actions } from '../../store/_actions/cursos';

const Cursos = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        const get_cursos_inscritos = () => dispatch(cursos_actions.getCursosInscritos());
        get_cursos_inscritos();
    },[]);

    const cursos = useSelector(state => state.cursos.cursos);
    const cursos_sin_Iniciar = cursos.filter(item=> item.progress == 0 || item.progress == null);

    const cursos_Iniciados = cursos.filter(item => item.progress < 100 && item.progress > 0 );
    const cursos_Terminados = cursos.filter(item => item.progress == 100 );
    return ( 
        <>
        <div className="sm:block lg:flex flex-col flex-wrap sm:flex-row">
            <Aside/>

            <div className="flex flex-wrap flex-col justify-center w-full lg:w-2/3 lg:flex-nowrap">
                <div className="w-full"> {/* overflow-hidden */}
                { cursos.length == cursos_Iniciados.length || cursos_Terminados.length 
                ? null : <CarouselList title="Mis cursos disponibles" cursos={cursos_sin_Iniciar} text="iniciar" /> }
                { cursos_Iniciados.length > 0 
                ? <CarouselList title="Cursos iniciados" cursos={cursos_Iniciados} text="continuar curso" /> : null }
                { cursos_Terminados.length > 0 
                ? <div id="finalizados"> <CarouselList title="Cursos finalizados" cursos={cursos_Terminados} text="ver nuevamente" /> </div> : null } 

                {/* { cursos.length == cursos_Iniciados.length || cursos_Terminados.length 
                ? null : <ListSlider MAX_VIDEOS_TO_SHOW={4} title="Mis cursos disponibles" cursos={cursos_sin_Iniciar} text="iniciar" /> }
                { cursos_Iniciados.length > 0 
                ? <ListSlider MAX_VIDEOS_TO_SHOW={4} title="Cursos iniciados" cursos={cursos_Iniciados} text="continuar curso" /> : null }
                { cursos_Terminados.length > 0 
                ? <ListSlider MAX_VIDEOS_TO_SHOW={4} title="Cursos finalizados" cursos={cursos_Terminados} text="ver nuevamente" /> : null }  */}
                </div>       
            </div>
        </div>
        </>
    );
}
 
export default Cursos;
import api_iac from '../../config/instance_axios';
import { config } from '../../config/user_token';


export const questions_service = {
    send_questions
};

async function send_questions(answers, attempt) {
    console.log(answers);
    //console.log(attempt);
    let user = JSON.parse(localStorage.getItem('user'));
    let answersArray = "";

    answers.map((answer, index) => {
        answersArray += `data[${index}][name]=${answer.nameAnswer}&data[${index}][value]=${answer.idAnswer}&`;
    });

    const request_answers = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=mod_quiz_process_attempt&
    moodlewsrestformat=json&
    attemptid=${attempt}&
    finishattempt=${1}&
    timeup=${0}&
    ${answersArray}`);

    if (typeof request_answers.data === 'object') {
        return 1;
    }

    const error = request_answers.data;
    return { error };
}
import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

import Nav from '../Nav/Nav';
import Footer from '../Footer';
import HeaderPerfil from '../Nav/HeaderPerfil';
import api_iac from '../../config/instance_axios';


export const PrivateRoute = ({ component: Component, ...rest }) => {
    let user = JSON.parse(localStorage.getItem('user'))
    return (
        <Route {...rest} render={props => (
            user ? 
                <div className="w-full lg:desktop" style={{ margin: '0 auto' }}>
                    <div className="flex">
                        <Nav/>
                        <div className="sm:block lg:flex flex-col w-full h-screen overflow-scroll scroll-custome">
                            <HeaderPerfil props={props}/>
                            <Component {...props} />
                            <Footer/>
                        </div>
                    </div>
                </div>
            :  <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )} />
    )
}
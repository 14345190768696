import React from 'react';
import { useSelector } from 'react-redux';


const DetallesUsuario = ({ user }) => {

    const authentication = useSelector(state => state.authentication );

    return ( 
        <div className="mb-5">
            <div className="text-blue-500 font-black text-2xl">Detalles de usuario</div>
            <div>
                <div className="mb-3">
                    <p className="text-gray-800 uppercase">Direccion Email</p>
                    <p className="text-blue-500"> { authentication.user.email } </p>
                    {/* <p className="text-blue-500"> { user.email } </p> */}
                </div>
                <div className="mb-3">
                    <p className="text-gray-800 uppercase">Pais</p>
                    <p className="text-blue-500"> { authentication.user.country } </p>
                </div>
                <div className="mb-3">
                    <p className="text-gray-800 uppercase">Perfil </p>
                    <p className="text-blue-500"> <span className="text-gray-600">Nombre: </span> { `${authentication.user.firstname} ${authentication.user.lastname}` } </p>
                    <p className="text-blue-500"> <span className="text-gray-600">Usuario: </span> { authentication.user.username } </p>
                    <p className="text-blue-500"> <span className="text-gray-600">Telefono: </span> { authentication.user.phone } </p>
                    <p className="text-blue-500"> <span className="text-gray-600">Departamento: </span> { authentication.user.department } </p>
                    <p className="text-blue-500"> <span className="text-gray-600">Institución: </span> { authentication.user.institution } </p>
                </div>
            </div>
        </div>
    );
}
 
export default DetallesUsuario;
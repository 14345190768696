import { communications_constants } from '../_constants/communications';


const initialState = {
    communications: [],
    loading_communications: null,
    error_communications: null,
};

export function communications_reducers(state = initialState, action) {
    switch (action.type) {
        //------------GET COMMUNICATIONS--------------//
        case communications_constants.COMMUNICATIONS_REQUEST:
            return {
                ...state,
                loading_communications: action.payload,
            };
        case communications_constants.COMMUNICATIONS_SUCCESS:
            return {
                ...state,
                loading_communications: false,
                communications: action.payload,
                error_communications: null
            };
        case communications_constants.COMMUNICATIONS_FAILURE:
            return {
                ...state,
                loading_communications: false,
                error_communications: action.payload
            };
        default:
            return state
    }
}
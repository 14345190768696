import { events_constants } from '../_constants/calendar';


const initialState = {
    events: [],
    loading_events: null,
    error_events: null
};

export function calendar_reducers(state = initialState, action) {
    switch (action.type) {
        //------------GET events--------------//
        case events_constants.EVENTS_REQUEST:
            return {
                ...state,
                loading_events: action.payload,
            };
        case events_constants.EVENTS_SUCCESS:
            return {
                ...state,
                loading_events: false,
                events: action.payload,
                error_events: null
            };
        case events_constants.EVENTS_FAILURE:
            return {
                ...state,
                loading_events: false,
                error_events: action.payload
            };
        default:
            return state
    }
}
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import download from '../../assets/img/download.png'
import api_iac from '../../config/instance_axios';


const Certificado = ({texto,id,custom,}) => {

    const user = useSelector(state => state.authentication.user );
    const [link, setLink] = useState('')

    useEffect(() => {
        if(custom == false ){
            download_certificado()
        }else{
            download_certificado_custom()
        }
    },[])

    const download_certificado = async () => {
        const request = await api_iac.post(`webservice/rest/server.php?
            wstoken=${user.token}&
            wsfunction=mod_certificate_get_user_certificate_download&
            moodlewsrestformat=json&
            certificateid=${id}&
            userid=${user.userid}
        `)
        setLink(`data:application/pdf;base64,${request.data.issues}`)
        // setLink(`${request.data.issues[0].fileurl}?token=${user.token}`)
    }

    const download_certificado_custom = async () => {
        const request = await api_iac.post(`webservice/rest/server.php?
            wstoken=${user.token}&
            wsfunction=mod_customcert_get_user_certificate_custom_view&
            moodlewsrestformat=json&
            certificateid=${id}&
            userid=${user.userid}
        `)
        setLink(`data:application/pdf;base64,${request.data.issues}`)
        // console.log(link)
    }
 
    return ( 
        <>
            <div className="flex w-full justify-between py-2 items-center">
                <div> <p className="text-lg text-gray-800 font-bold"> { texto } </p> </div>
                <a className="cursor-pointer" href={link} download={texto} target> <img src={download} alt=""/> </a>
            </div>
            <hr className="border-gray-400"/>
        </>
    );
}
 
export default Certificado;
import React from 'react';


const ActividadesRecientes = ({ cursos }) => {
    return ( 
        <div className="mb-5">
            <div className="text-blue-500 font-black text-2xl mb-2">Cursos Inscritos</div>
            <div>
                <div className="mb-3">
                    { 
                          cursos.map( course => {
                            return(
                                <p key={course.id} className="text-gray-800">{ course.shortname }</p>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}
 
export default ActividadesRecientes;
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

const CardSlider = ({ card, text, dateStart, disponible }) => {
  console.log(disponible);
  const history = useHistory();

  const redireccionar = (id) => history.push(`curso/${id}`);

  const [classNameUp, setClassNameUp] = useState('v-hidden')

  let background_image = '';
  const token = useSelector(state => state.authentication.user.token);

  if (card.overviewfiles[0]?.fileurl) {
    background_image = `${card.overviewfiles[0].fileurl}?token=${token}`;
  } else {
    background_image = require('../../assets/img/background_course.png').default
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setClassNameUp('effect visibility-v');
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    // my-5 mx-2
    <div className={`bg-white rounded-3xl overflow-hidden shadow-2xl w-48 max-h-84 ${classNameUp}`} style={{ transition: `right ${card.time}s, transform ${card.time}s` }}>

      <img
        className="w-full h-24"
        src={background_image}
        alt="Sunset in the mountains"
      />
      <div className="px-6 py-4 h-40 overflow-hidden">
        <div className="font-bold text-15 mb-2 text-blue-500" style={{ wordWrap: 'break-word' }}>{card.fullname}</div>

        <div className="overflow-hidden">
          <p className="text-grey-darker leading-4 overflow-hidden text-10" dangerouslySetInnerHTML={{ __html: card.summary }} />
        </div>


      </div>
      <div className="">
        {
          disponible === true ?
            <div className="text-center text-12">
              <p> Activo el: {dateStart}</p>
            </div>
            :
            <div className="h-5 text-center text-12">
            </div>
        }
      </div>
      <div className="px-6 py-4 flex justify-center">

        <button type="button" onClick={() => redireccionar(card.id)} className={`inline-block px-3 py-1 text-xs font-20 leading-6 text-center text-white uppercase transition bg-blue-main rounded-full shadow ripple hover:shadow-lg focus:outline-none ${disponible === true ? 'bg-opacity-50' : 'hover:bg-blue-800'}`} disabled={disponible}>
          {text}
        </button>

      </div>
    </div>
  );
};

export default CardSlider;

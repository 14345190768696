import React, { useEffect, useState } from "react";
import Calificacion from "../../Components/Calificaciones/Calificacion";
import Tag from "../../Components/Tag";
import { useDispatch, useSelector } from "react-redux";
import { cursos_actions } from "../../store/_actions/cursos";

const Calificaciones = () => {
  const dispatch = useDispatch();
  const [currentScore, setCurrentScore] = useState(null);

  useEffect(() => {
    const get_cursos_inscritos = () =>
      dispatch(cursos_actions.getCursosInscritos());
    get_cursos_inscritos();
  }, []);

  const cursos = useSelector((state) => state.cursos.cursos);
  const calificaciones = cursos.filter((item) => item.progress > 0);

  let total = 0;

  calificaciones.forEach((item) => {
    total += item.grade == "-" ? 0 : Number.parseFloat(item.grade);
  });

  const promedio = Number(total / calificaciones.length).toFixed(1);

  return (
    <div className="bg-white">
      <Tag
        imagen="trofeo.png"
        type="Promedio"
        number={promedio == "NaN" ? "0" : promedio}
      />

      <div className="flex min-h-main">
        <div className="px-8 py-14 lg:p-8 w-full lg:w-3/4 bg-white relative">
          <div className="pb-4 bg-white">
            <h3 className="text-2xl uppercase text-blue-500 font-black">
              Mis calificaciones
            </h3>
          </div>

          <div className="flex justify-between items-center bg-white pb-5">
            <div className="w-1/2 md:w-3/4"></div>
            <div className="w-2/12 md:w-1/12">
              <p className="text-gray-700">Avance</p>
            </div>
            <div className="w-3/12 md:w-3/12 -mr-2 lg:mr-0">
              <p className="text-gray-700">Calificación</p>
            </div>
          </div>

          <div className="bg-white">
            {calificaciones.map((curso) => (
              <Calificacion
                key={curso.id}
                curso={curso}
                currentScore={currentScore}
                setCurrentScore={setCurrentScore}
              />
            ))}
          </div>
        </div>
      </div>

      {/* <Footer/> */}
    </div>
  );
};

export default Calificaciones;

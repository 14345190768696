import React from 'react';
import descarga_certificado from '../../../../assets/img/descarga_certificado.svg'
import { useHistory } from 'react-router';

const CursoTerminado = ({curso}) => {
    const history = useHistory();

    const route = history.location.pathname;

    const redireccionar = () => history.push('certificados');

    return ( 
        <div className="flex items-center justify-between mb-3">
            <div className="w-10/12">
                <p className="text-sm">{curso.fullname}</p>
                <span className="text-xs text-gray-600">Curso finalizado</span>
                <div className={route === '/inicio' || route === '/eventos' || route === '/cursos' ? 'h-2 relative w-full rounded-full overflow-hidden mb-2' : 'h-4 relative w-10/12 rounded-full overflow-hidden mb-2'}>
                    <div className="w-full h-full bg-gray-700 absolute"></div>
                    <div className="h-full bg-green_bar absolute" style={{ width: '100%' }}></div>
                </div>
                <div className="flex flex-col justify-between">
                    <p className="text-xs bg-blue-main text-white p-0.5 w-6/12"> Nota: { Number.parseFloat(curso.grade).toFixed(2) } </p>
                    <div className="flex flex-row items-center">
                        <p className="text-xs text-gray-700 cursor-pointer border-b border-gray-400 pt-1 leading-3" onClick={ redireccionar }> Descargar certificado </p>
                    </div>
                </div>
            </div>
            <div className="w-2/12">
            <p className={route === '/inicio' || route === '/eventos' || route === '/cursos' ? "text-gray-800 text-12" : 'text-gray-700'}>{ Number.parseFloat(curso.progress).toFixed(2) }%</p>
            </div>
        </div>
    );
}
 
export default CursoTerminado;
export const forums_constants = {
    FORUMS_REQUEST: 'FORUMS_REQUEST',
    FORUMS_SUCCESS: 'FORUMS_SUCCESS',
    FORUMS_FAILURE: 'FORUMS_FAILURE',

    FORUMS_CREATE_REQUEST: 'FORUMS_CREATE_REQUEST',
    FORUMS_CREATE_SUCCESS: 'FORUMS_CREATE_SUCCESS',
    FORUMS_CREATE_FAILURE: 'FORUMS_CREATE_FAILURE',

    FORUMS_EDIT_REQUEST: 'FORUMS_EDIT_REQUEST',
    FORUMS_EDIT_SUCCESS: 'FORUMS_EDIT_SUCCESS',
    FORUMS_EDIT_FAILURE: 'FORUMS_EDIT_FAILURE',

    FORUMS_DELETE_REQUEST: 'FORUMS_DELETE_REQUEST',
    FORUMS_DELETE_SUCCESS: 'FORUMS_DELETE_SUCCESS',
    FORUMS_DELETE_FAILURE: 'FORUMS_DELETE_FAILURE',

    REMOVE_COMMENTS_FORUMS_REQUEST: 'REMOVE_COMMENTS_FORUMS_REQUEST',
    REMOVE_COMMENTS_FORUMS_SUCCESS: 'REMOVE_COMMENTS_FORUMS_SUCCESS',
    REMOVE_COMMENTS_FORUMS_FAILURE: 'REMOVE_COMMENTS_FORUMS_FAILURE',
}
import React, { useState } from 'react';
import FormAuth from '../../Components/FormAuth';

// Enrutamiento
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { userAuthActions } from "../../store/_actions/user_auth";



const Login = (props) => {
    const dispatch = useDispatch()
    const [user, setUser] = useState({
        username: '',
        password: '',
    })
    let [logo, set_logo] = useState(require('../../assets/img/logo.png').default)

    const cargadando = useSelector(state => state.loading );
    const error = useSelector(state => state.authentication.error );

    const handleChange = (e) => {
        setUser({ 
            ...user,
            [e.target.name]: e.target.value 
        });
    }

    const handleSubmit = (e) => {
        // Prevencion del envio de datos
        e.preventDefault();
        dispatch(userAuthActions.login(user.username, user.password))
    }
    
    return ( 
        <>
            <FormAuth>
                <h2 className="text-blue-500 text-center text-4xl">¡Bienvenido!</h2>
                {/* <div className="flex justify-center mb-4 w-full">
                    <img src ={logo} style={{width: '200px'}}/>
                </div> */}
                <form onSubmit={handleSubmit}>
                    { error != undefined ? <p className="p-2 mb-2 block bg-red-500 text-white text-center rounded">{error}</p> : null }
                    <div className="flex flex-col justify-center w-full mb-4">
                        <label className="text-md text-gray-10" htmlFor="username">Username</label>
                        <input onChange={handleChange} className="flex w-full bg-white rounded p-1" type="text" name="username" id="username"/>
                    </div>
                    <div className="flex flex-col justify-center w-full mb-4">
                        <label className="text-md text-gray-10" htmlFor="password">Contraseña</label>
                        <input onChange={handleChange} className="flex w-full bg-white rounded p-1" type="password" name="password" id="password"/>
                    </div>
                    <div className="flex items-center flex-col justify-between w-full mb-4 sm:flex-row justify-center">
                        <label className="text-md text-gray-10" htmlFor=""> <input onChange={handleChange} className="bg-gray-200 border-none" type="checkbox" name="" id=""/> Recordarme </label>
                        {/* <Link className="text-blue-500 text-md" to="/recuperar-contraseña">¿Olvidaste tu contraseña?</Link> */}
                    </div>
                
                    <div className="flex flex-col items-center justify-center w-full mb-4">
                        <button className="py-2 bg-blue-main text-white rounded-3xl flex justify-center w-4/5 font-bold text-xl hover:bg-blue-400">Entrar</button>
                    </div>
                    <div className="flex justify-between justify-center w-full mb-4">
                        {/* <p className="text-gray-10">¿No tienes cuenta?</p>
                        <Link className="text-blue-500 underline" to="/registro">Regístrate ahora</Link> */}
                    </div>
                </form>
            </FormAuth>
        </>
    );
}


export default Login;
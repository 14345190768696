import { cursos,curso } from '../_constants/cursos';
import { cursos_services } from '../_services/cursos';

import { alertActions } from './alert';

export const cursos_actions = {
    getCursosInscritos,
    getCurso
};

function getCursosInscritos() {
    return dispatch => {
        dispatch( request() );

        cursos_services.getCursosInscritos()
            .then(
                cursos => {
                    if(cursos.length > 0 ){
                        dispatch(success(cursos));
                    }else{
                        dispatch(failure(cursos.error.toString()));
                        dispatch(alertActions.error(cursos.error.toString()));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: cursos.CURSOS_REQUEST , payload: true } }
    function success(cursos_params) { return { type: cursos.CURSOS_SUCCESS , payload: cursos_params } }
    function failure(error) { return { type: cursos.CURSOS_FAILURE , payload: error } }
}

function getCurso(id) {
    return dispatch => {
        dispatch( request() );

        cursos_services.getCurso(id)
            .then(
                curso => {
                    if(curso.length > 0 ){
                        dispatch(success(curso));
                    }else{
                        dispatch(failure(curso.error.toString()));
                        dispatch(alertActions.error(curso.error.toString()));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: curso.CURSO_REQUEST , payload: true } }
    function success(cursos_params) { return { type: curso.CURSO_SUCCESS , payload: cursos_params } }
    function failure(error) { return { type: curso.CURSO_FAILURE , payload: error } }
}

import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import api_iac from '../../config/instance_axios';

const Secuencia = (props) => {
    const { scoid } = props.match.params;
    const [url, setUrl] = useState('')


    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('user'));
        getIframe(scoid, user.token)
    }, [])

    const getIframe = async (id, token) => {
        const consulta = await api_iac.post(`webservice/rest/server.php?
            wstoken=${token}&
            wsfunction=targett_api_open_scorm&
            scormid=${id}&
            moodlewsrestformat=json`
        );
        setUrl(consulta.data.url)

        // window.open(consulta.data.url)
        return consulta.data;
    }

    const user = useSelector(state => state.authentication.user );
    return <div className="bg-white h-full" dangerouslySetInnerHTML={{ __html: `<iframe src=${url}  id="iframe" class="w-full h-screen" />`}} />;

    /* return ( 
        <div className="bg-white h-full">

            <iframe id="iframe" className={`w-full h-screen`} allowfullscreen="allowfullscreen" webkitallowfullscreen="webkitallowfullscreen" mozallowfullscreen="mozallowfullscreen" src={url} frameborder="0" title="secuencia"/>
        </div>
    ); */
}

export default Secuencia;
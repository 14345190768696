import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

// Enrutamiento
import { NavLink } from "react-router-dom";

import inicio from '../../assets/img/iconos/desktop/bt_inicio.png'
import inicioHvr from '../../assets/img/iconos/desktop/bt_inicio_hvr.png'
import certificados from '../../assets/img/iconos/desktop/bt_certificados.png'
import certificadosHvr from '../../assets/img/iconos/desktop/bt_certificados_hvr.png'
import eventos from '../../assets/img/iconos/desktop/bt_eventos.png'
import eventosHvr from '../../assets/img/iconos/desktop/bt_eventos_hvr.png'
import cursos from '../../assets/img/iconos/desktop/bt_miscursos.png'
import cursosHvr from '../../assets/img/iconos/desktop/bt_miscursos_hvr.png'
import reconocimientos from '../../assets/img/iconos/desktop/bt_reconocimientos.png'
import reconocimientosHvr from '../../assets/img/iconos/desktop/bt_reconocimientos_hvr.png'
import calificacion from '../../assets/img/iconos/desktop/bt_calificaciones.png'
import calificacionHvr from '../../assets/img/iconos/desktop/bt_calificacion_hvr.png'


import regresar from '../../assets/img/regresar-ico.png'


const ItemsMenu = () => {

    let history = useHistory();
    
    function handleClick() {
        if(history.location.pathname.split('/')[1] == 'secuencia'){
            history.push(localStorage.getItem('urlBack'));
        }else{
            history.goBack();
        }
    }
// const [path, setPath] = useState('/inicio')

// const history = useHistory();

// useEffect(() => {
//     setPath(history.location.pathname)
// }, [])

//path == '/inicio' ? inicioHvr : 

    return (
        <div className="menu-items flex">
                <ul className="flex flex-col">
                    <li>
                        <NavLink className="opacity-img" exact activeClassName="active-change-inicio" to="/inicio"> <img style={{ width: '85px',height: '85px' }} src={inicio} 
                             onMouseOver={e => (e.currentTarget.src = inicioHvr)}
                             onMouseOut={e => (e.currentTarget.src = inicio)} 
                        /></NavLink>
                    </li>
                    <li>
                        <NavLink className="opacity-img" exact activeClassName="active-change-cursos" to="/cursos"><img style={{ width: '85px',height: '85px' }} src={cursos}
                            onMouseOver={e => (e.currentTarget.src = cursosHvr)}
                            onMouseOut={e => (e.currentTarget.src = cursos)} 
                        /></NavLink>                  
                    </li>
                    <li>
                        <NavLink className="opacity-img" exact activeClassName="active-change-eventos" to="/eventos"><img style={{ width: '85px',height: '85px' }} src={eventos} 
                            onMouseOver={e => (e.currentTarget.src = eventosHvr)}
                            onMouseOut={e => (e.currentTarget.src = eventos)}  
                        /></NavLink>
                    </li>
                    <li>
                        <NavLink className="opacity-img" exact activeClassName="active-change-calificaciones" to="/calificaciones"><img style={{ width: '85px',height: '85px' }} src={calificacion} 
                            onMouseOver={e => (e.currentTarget.src = calificacionHvr)}
                            onMouseOut={e => (e.currentTarget.src = calificacion)} 
                        /></NavLink>
                    </li>
                    <li>
                        <NavLink className="opacity-img" exact activeClassName="active-change-certificados" to="/certificados"><img style={{ width: '85px',height: '85px' }} src={certificados} 
                            onMouseOver={e => (e.currentTarget.src = certificadosHvr)}
                            onMouseOut={e => (e.currentTarget.src = certificados)}
                        /></NavLink>
                    </li>
                    <li>
                         {
                            // <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/recompensas"> <img style={{ width: '85px',height: '85px' }} src={recompensas} /></NavLink>
                         }
                    </li>
                    <li>
                        <NavLink className="opacity-img" exact activeClassName="active-change-reconocimientos" to="/reconocimientos"><img style={{ width: '85px',height: '85px' }} src={reconocimientos} 
                            onMouseOver={e => (e.currentTarget.src = reconocimientosHvr)}
                            onMouseOut={e => (e.currentTarget.src = reconocimientos)}
                        /></NavLink>
                    </li>
                    {  history.location.pathname.split('/')[1] == 'secuencia' ? 
                    
                         <li>
                            <div className="flex justify-center cursor-pointer">
                                <div onClick={handleClick}>
                                    <img src={regresar} alt=""/>
                                </div> 
                            </div>
                        </li>
                         
                    :  <></> }
                  
                    {/* <li>
                        {
                            <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/videoconferencias"><img style={{ width: '85px',height: '85px' }} src={videoconferencias} /></NavLink>
                        }
                    </li> */}
                </ul>
            </div>
    );
}
 
export default ItemsMenu;
import React from 'react';


const VideoConferencia = ({ reportes }) => {
    return ( 
        <div className="mb-5 hm-shadow p-4 rounded-2xl">
            <div className="text-blue-500 font-black text-2xl mb-2">Reportes</div>
            <div>
                <div className="mb-3">
                    { 
                        reportes.map( item => {
                            return(
                                <p className="text-gray-800">{ item }</p>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}
 
export default VideoConferencia;
import React, { useEffect } from 'react';
import Certificado from '../../Components/Certificados/Certificado';
import Tag from '../../Components/Tag';
import { useDispatch, useSelector } from 'react-redux';
import { certificados_actions } from '../../store/_actions/certificados'

const Certificados = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const get_certificados = () => dispatch(certificados_actions.getCertificados());
        const get_certificados_custom = () => dispatch(certificados_actions.getCertificadosCustom());
        get_certificados();
        get_certificados_custom();
    },[]);

  
    const certificados = useSelector(state => state.certificados.certificados);
    const custom_certificados = useSelector(state => state.certificados.custom_certificados);

    const certificado_custom_loading = useSelector(state => state.certificados.certificado_custom_loading )
    const certificado_loading = useSelector(state => state.certificados.certificado_loading )


    return ( 
        <div className="bg-white">
        { certificado_loading == false &&  certificado_custom_loading == false  ?
      <Tag imagen="certificados.png" type="Certificados" number={ custom_certificados.length + certificados.length}/>
      : null }
      <div className="flex justify-between">
          <div className="p-8 w-full sm:w-1/2">
              <div className="mb-4"><h3 className="text-2xl uppercase text-blue-500 font-black">Mis CERTIFICADOS</h3></div>
  
              <div>
                  { certificado_loading == false ? 
                    certificados.length > 0 ? certificados.map(item => ( <Certificado key={`${item.id}${item.name}`} id={item.certificateid} texto={item.name} custom={false} /> )) 
                  : ''
                  : '' }
              </div>  
              <div>
                  { certificado_custom_loading == false  ?
                      custom_certificados.length > 0 ?
                          custom_certificados.map(item => (
                              <Certificado key={`${item.id}`} id={item.certificateid} texto={item.name} custom={true} />
                          ))
                      : ''  
                  : 'Cargando...' }
              </div>  
          </div>
      </div>
  </div>

    );
}
 
export default Certificados;
import React from 'react';

const FormAuth = (props) => {
    return ( 
        <div className="flex justify-center min-h-screen m-auto">
            <div className="flex justify-center flex-col items-center dafi-fondo-auth">
                <div className="bg-white bg-opacity-70 py-12 px-8 w-72 sm:w-80 md:w-96 lg:w-96 xl:w-96 shadow-xl rounded-xl mb-4">
                    <div>
                        <div>
                            {props.children}
                        </div>    
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default FormAuth;
import { combineReducers } from 'redux'

import { authentication } from './authentication'
import { cursos_reducers as cursos } from './cursos'
import { reconocimientos_reducers as reconocimientos } from './reconocimientos'
import { certificados_reducers as certificados } from './certificados'
import { calendar_reducers as calendar } from './calendar'
import { questions_reducers as questions } from './questions'
import { forums_reducers as forums } from './forums'
import { communications_reducers as communications } from './communications'



const allReducers = combineReducers({
  authentication,
  cursos,
  reconocimientos,
  certificados,
  calendar,
  questions,
  forums,
  communications
})

export default allReducers

import { user_login } from '../_constants/user_auth';


let user = JSON.parse(localStorage.getItem('user'));

const initialState = user ? { loggedIn: false, loading: false ,user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case user_login.LOGIN_REQUEST:
      return {
        loggingIn: false,
        loading: action.payload,
      };
    case user_login.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        loading: false,
        user: action.payload
      };
    case user_login.LOGIN_FAILURE:
      return {
        loggedIn: false,
        loading: false,
        error: action.payload
      };
    case user_login.LOGOUT:
      return {};
    default:
      return state
  }
}
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cursos_actions } from '../../store/_actions/cursos';
import { Link, useHistory } from "react-router-dom";
import ListaItemCurso from '../../Components/Curso/ListaItemCurso';

import lock_gray from '../../assets/img/lock_gray.png';
import success from '../../assets/img/success.png';


const Curso = (props) => {

    const dispatch = useDispatch();
    const { id } = props.match.params;

    useEffect(() => {
        const getCurso = () => dispatch(cursos_actions.getCurso(id));
        const getCursos = () => dispatch(cursos_actions.getCursosInscritos());
        getCurso();
        getCursos();
    }, [])


    const detalles_cursos = useSelector(state => state.cursos.cursos);
    const curso = useSelector(state => state.cursos.curso);

    const detalles_curso = detalles_cursos.filter(item => item.id == id)[0];

    return (
        <div className="bg-white p-4">
            {detalles_cursos.length > 0 ?
                <div className="flex">
                    <div className="p-1 md:p-8 w-full">
                        <div className="m-auto mb-4">
                            <h3 className="text-2xl text-blue-500 font-black">Acerca de este curso</h3>
                            <p className="text-xl" dangerouslySetInnerHTML={{ __html: detalles_curso.summary }} />
                        </div>
                        <div>
                            <p>Da click en el icono de Play</p>
                        </div>
                        <div>
                            {curso.map((item, i) => {
                                return (
                                    <div className="m-auto mb-8 w-full">
                                        <h3 className="text-xl text-gray-800 font-black mb-4">
                                            {item.section == 0 ? null : <p>{i}. {item.name}</p>}
                                        </h3>

                                        <div>
                                            {item.modules.length > 0 ?
                                                item.modules.map(modulo => (
                                                    <ListaItemCurso modulo_prop={modulo} itemId={item.id} idCurso={id} />
                                                ))
                                                : <p>Sin contenido</p>}
                                        </div>

                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                : 'Cargando'}
        </div>
    );
}

export default Curso;
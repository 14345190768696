import React from 'react';
import trofeo from '../../../../assets/img/trofeo.png'
import plus from '../../../../assets/img/plus.svg'


const Promedio = ({promedio}) => {
    return ( 
        <div className="flex flex-col bg-black items-center w-5/7 mb-3 p-4 rounded-xl">
               <img className="h-16" src={trofeo} alt="" />
          
               <p className="text-white text-29 leading-7">{promedio == 'NaN' ? '0' : promedio}</p>
               <p className="text-16 text-gray-400 leading-4">Promedio</p>
                <a className="text-16 text-gray-400 mt-5">Avance</a>
               {/* <div className="flex flex-row items-center mb-5 text-right justify-end"> <img className="mr-1" style={{ width: '10px', height: '10px' }} src={plus} alt=""/> <p className="uppercase text-xs text-gray-400 ">ver más</p> </div>   */}
        </div>
    );
}
 
export default Promedio;
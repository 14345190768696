import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';


import cursos_iniciados from '../../../assets/img/cursos_iniciados.png'
import cursos_terminados from '../../../assets/img/cursos_terminados.png'
import CursoIniciado from './Curso/CursoIniciado';
import CursoTerminado from './Curso/CursoTerminado';
import Promedio from './Curso/Promedio';
import PromedioDash from './Curso/PromedioDash';
import { Link } from 'react-router-dom'

const Cursos = () => {
    const cursos_inscritos = useSelector(state =>  state.cursos.cursos);

    const cursos_Iniciados = cursos_inscritos.filter(item => item.progress < 100 && item.progress > 0 );
    const cursos_Terminados = cursos_inscritos.filter(item => item.progress == 100 );

   let total = 0;

   cursos_inscritos.forEach(item => {
        total += item.grade == "-" ? 0 : Number.parseFloat(item.grade);
    });

   const promedio = Number(total / cursos_inscritos.length ).toFixed(1);

    return (  
        <div className="flex justify-center flex-col w-full mt-2">

            <div className="bg-white rounded-xl w-full p-6 mt-2">
            <h2 className="text-blue-main text-center text-2xl font-light my-2">Mis cursos</h2>

                {/* Cursos Iniciados */}
                <div className="flex items-center mb-2">
                    <div>
                        <img src={cursos_iniciados} alt=""/>
                    </div>
                    <div className="flex items-center text-blue-main">
                        <div className="mr-2"> <p className="text-xl font-black uppercase">0{ cursos_Iniciados.length }</p> </div>
                        <div> <p className="text-sm font-black uppercase">Cursos iniciados</p> </div>
                    </div>
                    <div className="flex-1"> <Link to="/cursos" className="block text-right text-xs text-gray-600">Ver cursos</Link> </div>
                </div>

                {   cursos_Iniciados.length > 0 ?
                        cursos_Iniciados.map(curso => <CursoIniciado curso={curso} key={curso.id}/> )
                    : <p className="mb-3 text-xl">No tienes cursos iniciados</p>
                }

                {/* Cursos Terminados */}
                <div className="flex items-center mb-2">
                    <div>
                        <img src={cursos_terminados} alt=""/>
                    </div>
                    <div className="flex items-center text-blue-main">
                        <div className="mr-2"> <p className="text-xl font-black uppercase">0{cursos_Terminados.length}</p> </div>
                        <div> <p className="text-sm font-black uppercase">Cursos terminados</p> </div>
                    </div>
                    <div className="flex-1"> <Link to="/cursos#finalizados" className="block text-right text-xs text-gray-600">Ver cursos</Link> </div>
                </div>  

                {   cursos_Terminados.length > 0 ?
                        cursos_Terminados.map(curso => <CursoTerminado curso={curso} key={curso.id}/> )
                    : <p className="mb-3 text-xl">No tienes cursos terminados</p>
                }  

                <div className="flex justify-center">
                    <PromedioDash promedio={promedio}/>
                </div>

            </div>
        </div>
    );
}
 
export default Cursos;
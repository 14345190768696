import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import DetailsScore from "./DetailsScore";
import { cursos_actions } from "../../store/_actions/cursos";

const Calificacion = ({ curso, currentScore, setCurrentScore }) => {
  const isCurrentScore = currentScore === curso.id;

  // Redux
  const dispatch = useDispatch();
  const getCourseContent = (id) => dispatch(cursos_actions.getCurso(id));

  const handleClickShowDetails = (e) => {
    console.log(e.target.nextSibling.style);
    var target = document.querySelector(`#${e.target.nextSibling.id}`);
    console.log(target);
    var player = target.animate([
      {
        transform: 'translate(5px, 10px)',
        opacity: 0.3,
        zIndex: 100
      },
      {transform: 'translate(5px, 50%)',opacity: 0.8,zIndex: 100}
    ], 500);
    player.addEventListener('finish', function() {
      target.style.transform = 'translate(5px, 50%)';
      target.style.zIndex = 100;
    });
    //setCurrentScore(curso.id);
  };

  const resetCurrentScore = (e) => {
    console.log(e.target.parentElement.parentElement.id);

    var target = document.querySelector(`#${e.target.parentElement.parentElement.id}`);
    var player = target.animate([
      {transform: 'translate(5px, 50%)',opacity: 0.8,zIndex: 100},
      {
        transform: 'translate(5px, 10px)',
        opacity: 0.3,
        zIndex: 100
      }
    ], 500);
    player.addEventListener('finish', function() {
      target.style.transform = 'translate(5px, 10px)';
      target.style.zIndex = -100;
    });
    //setCurrentScore(null);
  };

  useEffect(() => {
    if (currentScore === curso.id) {
      getCourseContent(curso.id);
    }
  }, [currentScore]);

  const isScore = () => {
    let score = curso.grade;

    if (score == "-") {
      let str = score.replace(score, "0");
      let convertToNumber = Number(str);
      return Number.parseFloat(convertToNumber).toFixed(1);
    }

    return Number.parseFloat(score).toFixed(1);
  };

  return (
    <div className="flex justify-between items-center pb-4 relative -ml-4 md:ml-0">
      <div className="w-1/2 md:w-3/4">
        <h4 className="text-xs uppercase text-gray-800 md:text-base">
          {curso.displayname}
        </h4>
        <div className="h-4 relative w-11/12 rounded-full overflow-hidden mb-2">
          <div className="w-full h-full bg-gray-700 absolute"></div>
          <div
            className="h-full bg-green_bar absolute"
            style={{ width: `${curso.progress}%` }}
          ></div>
        </div>
      </div>
      <div className="w-2/12 md:w-1/12">
        <p className="text-gray-700">
          {Number.parseFloat(curso.progress).toFixed(1)}%
        </p>
      </div>
      <div className="w-3/12 md:w-3/12 flex items-end -mr-4 d-flex flex-col md:flex-row">
        <p className="w-55p text-blue-500 font-bold">{isScore()}</p>
        <p className="text-blue-500 text-xs cursor-pointer" onClick={handleClickShowDetails}>
          ver detalle
        </p>
        <DetailsScore
          cardId={curso.id}
          curso={curso}
          isCurrentScore={isCurrentScore}
          isScore={isScore}
          onClose={resetCurrentScore}
        />
      </div>
    </div>
  );
};

export default Calificacion;
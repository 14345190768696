import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';

import inicio from '../../assets/img/iconos/mobile/inicio.png'
import certificados from '../../assets/img/iconos/mobile/certificados.png'
import eventos from '../../assets/img/iconos/mobile/eventos.png'
import cursos from '../../assets/img/iconos/mobile/cursos.png'
import recompensas from '../../assets/img/iconos/mobile/recompensas.png'
import reconocimientos from '../../assets/img/iconos/mobile/reconocimientos.png'
import videoconferencias from '../../assets/img/iconos/mobile/video.png'
import calificacion from '../../assets/img/iconos/mobile/calificacion.png'
import { useHistory } from "react-router-dom";
import regresar from '../../assets/img/regresar2.png'

const MenuHamburguesa = () => {
    let history = useHistory();
    const [open, setOpen] = useState(false)

    function handleClick() {
        if(history.location.pathname.split('/')[1] == 'secuencia'){
            history.push(localStorage.getItem('urlBack'));
        }else{
            history.goBack();
        }
    }

    return (
        <div  onClick={() => setOpen(!open) }  className="content_menu_hamburguesa">
            <div className="menu_hamburguesa flex flex-col items-center justify-center">
                <div className="bg-white mb-2" style={{ width: '35px', height:'4px' }}></div>
                <div className="bg-white mb-2" style={{ width: '35px', height:'4px' }}></div>
                <div className="bg-white" style={{ width: '35px', height:'4px' }}></div>
            </div>

            { open ? 
                <div>
                    <div style={{ top: '85px' }} onClick={ () => setOpen(false) } className="fixed top-0 left-0 bg-blue-700 bg-opacity-50 z-40 min-h-screen w-full"></div>
                
                    <div onClick={()=>setOpen(!open)} className="menu_hamburguesa_items p-1 bg-black">
                        <ul>
                            <li> <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/inicio"> <img style={{ width:'150px', maxWidth: 'unset' }} src={inicio} alt=""/> </NavLink>  </li>
                            <li> <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/eventos"> <img style={{ width:'150px', maxWidth: 'unset' }} src={eventos} alt=""/> </NavLink>  </li>
                            <li> <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/cursos"> <img style={{ width:'150px', maxWidth: 'unset' }} src={cursos} alt=""/> </NavLink>  </li>
                            <li> <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/calificaciones"> <img style={{ width:'150px', maxWidth: 'unset' }} src={calificacion} alt=""/> </NavLink>  </li>
                            <li> <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/certificados"> <img style={{ width:'150px', maxWidth: 'unset' }} src={certificados} alt=""/> </NavLink>  </li>
                            {/* <li> <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/recompensas"> <img style={{ width:'150px', maxWidth: 'unset' }} src={recompensas} alt=""/> </NavLink>  </li> */}
                            <li> <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/reconocimientos"> <img style={{ width:'150px', maxWidth: 'unset' }} src={reconocimientos} alt=""/> </NavLink>  </li>
                            {/* <li> <NavLink className="opacity-img" exact activeClassName="active-opacity-img" to="/videoconferencias"> <img style={{ width:'150px', maxWidth: 'unset' }} src={videoconferencias} alt=""/> </NavLink>  </li> */}
                            {  history.location.pathname.split('/')[1] == 'secuencia' ? 
                                <li>
                                    <div  onClick={handleClick} className="flex items-center justify-start cursor-pointer pb-1">
                                        <div>
                                            <img  style={{ width:'35px', maxWidth: 'unset', marginLeft: '12px' }} src={regresar} alt=""/>
                                        </div> 
                                        <div>
                                            <p className="text-white ml-1">Regresar</p>
                                        </div>
                                    </div>
                                </li>
                                    
                            :  <></> }
                        </ul>
                    </div>
                </div>
            : null }
        </div>
    );
}
 
export default MenuHamburguesa;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DayPicker from 'react-day-picker';
import moment from 'moment';
import 'react-day-picker/lib/style.css';

const WEEKDAYS_SHORT = {
    es: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
};
const MONTHS = {
    es: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
    ],
};

const WEEKDAYS_LONG = {
    es: [
        'Domingo',
        'Lunes',
        'Martes',
        'Miercoles',
        'Jueves',
        'Viernes',
        'Sabado',
    ],
};

const FIRST_DAY_OF_WEEK = {
    es: 1,
};
// Translate aria-labels
const LABELS = {
    es: { nextMonth: 'Proximo mes', previousMonth: 'Mes anterior' },
};

function formatDate(date) {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    let newFormat = new Date(date).toLocaleDateString("es-ES", options);
    return newFormat;
}

function formatDateActual(date) {
    const options = { month: 'short', day: 'numeric' };
    let newFormat = new Date(date).toLocaleDateString("es-ES", options);
    return newFormat;
}

const Calendario = () => {
    //const dispatch = useDispatch();
    const arrayEvents = useSelector(state => state.calendar.events);
    //const arrayMeetings = useSelector(state => state.meetings.meetingsAll);
    const arrayMeetings = [];

    const [seletedDate, setSeletedDate] = useState(new Date());
    const [ArrayMeetingSelected, setArrayMeetingSelected] = useState([]);

    const conferencias = [];
    arrayMeetings.map((meetings) => {
        conferencias.push(new Date(meetings.start_time.substring(0, 19)));
    });

    const handleDayClick = (day) => {
        let formatDaySelected = moment(day).format('YYYY-MM-DD');

        const arrayMeetingDay = [];
        arrayMeetings.map((meetings) => {
            if (formatDaySelected === meetings.start_time.substring(0, 10)) {
                arrayMeetingDay.push(meetings);
            }
        });

        setArrayMeetingSelected(arrayMeetingDay);
        setSeletedDate(day);
    }


    return (
        <>
            <div>
                <div className="mb-3"> <h4 className="text-white text-4xl" >Calendario</h4> </div>

                <div className="flex flex-col justify-center rounded-2xl hm-shadow bg-white">
                    <DayPicker
                        showOutsideDays
                        showWeekNumbers
                        todayButton="Ir al dia de hoy"
                        months={MONTHS['es']}
                        weekdaysLong={WEEKDAYS_LONG['es']}
                        weekdaysShort={WEEKDAYS_SHORT['es']}
                        firstDayOfWeek={FIRST_DAY_OF_WEEK['es']}
                        labels={LABELS['es']}
                        initialMonth={new Date()}
                        selectedDays={conferencias}
                        onDayClick={handleDayClick}
                    />

                    <div className="">
                        <div className="p-2 flex justify-evenly items-center">
                            <div className="text-md mr-2 w-5/12 text-center">
                                <p className="text-gray-700 text-xs">Eventos del dia</p>
                                <p className="text-gray-800 text-sm">{formatDateActual(seletedDate)}</p>
                            </div>
                            <div className="bg-gray-700 text-white p-1 rounded">
                                <ul>
                                    {
                                        ArrayMeetingSelected.length > 0 ?
                                            ArrayMeetingSelected.map(item =>
                                                <li className="mb-2"><span>{formatDate(item.start_time)}</span> {item.topic} <a className="hover:bg-blue-900 p-1" href={item.join_url} target="_blank" rel="noreferrer">Link</a></li>
                                            ) :
                                            <p>No tienes evento en este dia</p>
                                    }
                                </ul>
                            </div>
                        </div>

                        {/* <div className="p-2 flex justify-evenly items-center">
                            <div className="m-1 text-xs">
                                <p className="m-1 text-gray-700 text-xs">Eventos del dia</p>
                                <p className="m-1 text-gray-800 text-sm">20 de enero</p>
                            </div>
                            <div className="bg-gray-700 text-white p-1 rounded">
                                <ul>
                                    <li>15 10:00 Conferencias</li>
                                    <li>15 10:00 Conferencias</li>
                                    <li>15 10:00 Conferencias</li>
                                </ul>
                            </div>
                        </div> */}
                    </div>

                </div>

            </div>
        </>
    );
}

export default Calendario;
import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import api_iac from '../../config/instance_axios';

const Recurso = (props) => {
    const { courseid, recursoid } = props.match.params;
    const [url, setUrl] = useState('')

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('user'));
        getIframe(courseid, user.token, recursoid)
    }, [])

    const getIframe = async (idcourse, token, idrecurso) => {

        const consulta = await api_iac.post(`webservice/rest/server.php?
            wstoken=${token}&
            wsfunction=mod_resource_get_resources_by_courses&
            moodlewsrestformat=json&
            courseids[0]=${idcourse}`
        ).then(function (response) {
            const resourcesItems = response.data.resources;
            const detailsResource = resourcesItems.filter(resource => resource.id == idrecurso);

            const urlResource = detailsResource[0].contentfiles[0].fileurl + `?token=${token}`;
            setUrl(urlResource);

            const viewed = api_iac.post(`webservice/rest/server.php?
                wstoken=${token}&
                wsfunction=mod_resource_view_resource&
                moodlewsrestformat=json&
                resourceid=${idrecurso}`
            );
        }).catch(function (error) {
            console.log(error);
        });
    }

    const user = useSelector(state => state.authentication.user);

    return (
        <div className="aspect-w-16 aspect-h-9 customContainer mx-auto bg-white h-screen">
            <iframe id="iframe" className={`w-full h-full`} src={`${url}#view=fitH`} frameborder="0" title="url" />
        </div>
    );
}

export default Recurso;
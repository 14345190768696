import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Buscador from './Buscador';
import MenuOpciones from './../Nav/MenuOpciones';

import logo from './../../assets/img/logo.png';
import Welcome from './Welcome';
import fondo from './../../assets/img/fondo.jpg';
import MenuMobile from '../MenuMobile/MenuMobile';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { userAuthActions } from '../../store/_actions/user_auth';
import Navbar from './Navbar.scss'

// Images background header
import inicio from '../../assets/img/header/imgback_inicio.jpeg'
import certificados from '../../assets/img/header/imgback_certificado.jpeg'
import eventos from '../../assets/img/header/imgback_eventos.jpeg'
import cursos from '../../assets/img/header/imgback_cursos.jpeg'
import reconocimientos from '../../assets/img/header/imgback_reconocimientos.jpeg'
import calificacion from '../../assets/img/header/imgback_calificacion.jpeg'
import recompensas from '../../assets/img/header/imgback_recompensas.jpeg'
import videoconf from '../../assets/img/header/imgback_videoconf.jpeg'

const HeaderPerfil = ({props}) => {
  
    // allowfullscreen="allowfullscreen" webkitallowfullscreen="webkitallowfullscreen" mozallowfullscreen="mozallowfullscreen"
    const {id} = props.match.params;
    let url = props.match.url.split("/")[1];
    
    const dispatch = useDispatch();
    const user = useSelector(state => state.authentication.user );
    const lista_cursos = useSelector(state => state.cursos.cursos);

    const history = useHistory();
    const route = history.location.pathname;

    const logoutUser = () => {
        dispatch(userAuthActions.logout());
    }

    let text = '';
    let fondo = '';
   
    switch (history.location.pathname.split('/')[1]) {
        case 'inicio':
            fondo = inicio
            break;
        case 'cursos':
            fondo = cursos
            break;
        case 'eventos':
            fondo = eventos
            break;
        case 'calificaciones':
            fondo = calificacion
            break;
        case 'certificados':
            fondo = certificados
            break;
        case 'recompensas':
            fondo = recompensas
            break;
        case 'reconocimientos':
            fondo = reconocimientos
            break;
        case 'videoconferencias':
            fondo = videoconf
            break;
        default:
            fondo = inicio
            break;
    }
    
    const authentication = useSelector(state => state.authentication );
    const curso = lista_cursos.filter(item => item.id == id);
    
    return ( 
        <>
        {/* { url !== 'secuencia' ? <></> : <div className="bg-black"></div> } */}
        <div className={`flex w-full justify-between items-center p-2 ${ url !== 'secuencia' ? 'bg-black bg-opacity-70 fixed nav-size' : 'bg-black static w-full p-5' } z-50 w-navMovil `}>
            <div className="flex items-center">
                <div className="mx-2 md:mx-4 w-1/4"> <Link to="/"><img src={logo} alt="logo"/></Link>  </div>
                <div className="text-white text-xs lg:text-xl lg:ml-6"> <p>Plataforma</p> <p>Targett</p> </div>
            </div>
            <div className="flex items-center">
                <Buscador/>
                <MenuMobile/>
                <div className="hidden lg:flex"><MenuOpciones/></div>
                <button onClick={logoutUser} className="hidden mx-1 py-1 px-2 lg:block bg-gray-300 text-blue-800 rounded-3xl hover:bg-blue-main hover:text-white"> <Link className="text-xs" to="/login">Cerrar sesión</Link> </button>   
            </div>
        </div>
        { url !== 'secuencia' ? 
        <header className="flex flex-col w-full bg-center bg-cover" style={{ backgroundImage: `url("${fondo}")` }}>
            <div>
                <div className="w-navMovil lg:p-4 lg:w-navDesktop" style={{ minHeight: '85px'}}></div>
                { url !== 'curso' ? 
                    <div className="flex items-center">
                        <div className="p-4 lg:w-1/3 w-full flex flex-col justify-center items-center my-8">
                            <div className="flex justify-center flex-col items-center text-center profile">
                                <div style={{ height: "113px", borderRadius: "50%", backgroundColor: '#f5f5f5' }} className="bg-blue-500 m-0 overflow-hidden">
                                    <img className="m-0 h-full overflow-hidden" src={authentication.user.profileimage} alt=""/>
                                </div>       
                            </div>
                            <div className="flex justify-center flex-col items-center text-center mt-3">
                                <div>
                                    <h2 className="mb-2 text-white font-bold text-xl">{ authentication.user.firstname } { authentication.user.lastname }</h2>
                                    <h2 className="line-profile"></h2>
                                
                                    <p className="text-white mb-1 text-sm mt-3">Área Profesional</p>
                                    <p className="text-white mb-1 text-sm">Asistente Regional</p>
                                </div>
                            </div>
                        </div>

                        <div style={{ overflow: 'overlay', backgroundImage: `url(${fondo.default})` }} className="flex justify-end items-center w-full lg:w-2/3 h-72 lg:h-auto bg-no-repeat bg-cover bg-center">
                                { route === '/inicio' ?  <Welcome /> : null }
                                <div className="hidden lg:block text-white text-2xl w-2/5" dangerouslySetInnerHTML={{ __html: text  }}></div>
                        </div>
                    </div>
                :
                    <div className="flex justify-start items-center" style={{ height: "213px" }}>
                        <div className="hidden bg-white py-3 m-4 px-6 md:block">
                            <p className="text-blue-main text-26"> <span className="text-black font-bold">¡Bienvenido a</span> { curso[0] == undefined ? '' : curso[0].fullname } </p>
                        </div> 
                    </div> 
                }
            </div>
        </header>
        : <></> }
        </>
    );
}
 
export default HeaderPerfil;